* {
  margin: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
  font-weight: lighter;
}

body {
  font: 2rem "Montserrat", sans-serif;
  height: 100vh;
}

li {
  list-style: none;
}

ul {
  padding: unset;
}
