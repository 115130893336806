.servicesHeaderContainer {
  background: radial-gradient(
        circle at 0% 50%,
        rgba(1, 1, 29, 1) 9px,
        rgba(0, 0, 0, 1) 10px,
        rgba(1, 1, 29, 1) 11px
      )
      0px 10px,
    radial-gradient(
      at 100% 100%,
      rgba(1, 1, 29, 1) 9px,
      rgba(0, 0, 0, 0.2) 10px,
      rgba(1, 1, 29, 1) 11px
    ),
    #8a3;
  background-size: 20px 20px;
  color: #fff;
  padding: 3rem 3rem 5rem;
}
.servicesHeader {
  width: 80%;
  margin: 0 auto;
  padding: 2rem;
  margin: 0 auto;
}

.servicesHeader h1 {
  font-size: 5rem;
}

.servicesHeader p {
  width: 65%;
  margin: 0 auto;
  padding: 1rem;
}

.servicesContainer {
  width: 80%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  gap: 2rem;
}

.serviceImageContainer {
  width: 100%;
  height: 200px;
  overflow: hidden;
  position: relative;
  padding: 1rem;
}

.serviceImageContainer img {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
}

.serviceText h2 {
  padding: 2rem;
}

.serviceText p {
  padding: 2rem;
  text-align: justify;
}

.serviceGridItem {
  margin: -3rem 0 3rem;
  border: 1px solid rgba(128, 128, 128, 0.3);
  border-radius: 1rem;
}

@media screen and (max-width: 850px) {
  .servicesHeader {
    width: 90%;
  }

  .servicesHeader h1 {
    font-size: 4rem;
  }
}

@media screen and (max-width: 650px) {
  .servicesHeader h1 {
    font-size: 3rem;
  }

  .servicesHeader p {
    width: 100%;
    margin: 0 auto;
    padding: 1rem;
  }

  .servicesContainer {
    margin: 1rem auto;
  }

  .serviceText h2 {
    font-size: 2.5rem;
    padding: 0.5rem;
  }

  .serviceText p {
    font-size: 2rem;
    padding: 0 initial;
  }
}
